<template>
  <d-remote-monitoring-stats />
</template>

<script>

export default {
  name: 'RemoteMonitoringStats',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },
};
</script>
